import styled from 'styled-components'
import { Text } from '@caterdesk/ui--typography'
import React, { useEffect, useMemo, useState } from 'react'
import RedesignedMenuItemCardAug2024 from '../menu-item-card/redesignAug2024'
import { MenuCategory } from '../../domain/menu-category'
import { MenuVendor } from '../../domain/menu-vendor'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import { ItemView } from '@/components/page-specific/vp/legacy/shared/components/layouts'
import LoadingIndicator from '@/components/LoadingIndicator'
import { Box, Grid as MuiGrid, useMediaQuery, useTheme } from '@mui/material'
import { List, WindowScroller } from 'react-virtualized'
import 'react-virtualized/styles.css'

const CategorySection = styled.div`
  margin-bottom: 45px;
`

const CategoryTitle = styled.h2`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 22px;
  color: ${(p) => p.theme.colors.primary};
  text-transform: uppercase;
  margin: 0 0 ${(p) => p.theme.spacings[3]}px;
`

const CategoryDivider = styled.div`
  background: linear-gradient(
    to right,
    ${(p) => p.theme.colors.primary} 0%,
    rgba(79, 191, 112, 0.0001) 98.64%
  );
  mix-blend-mode: normal;
  opacity: 0.4;
  height: 2px;
  width: 50%;
  margin: 16px 0;
`

const MassiveBox = styled.div`
  margin: 100px 0;
  text-align: center;
`

export type Props = {
  display: ItemView
  vendor: MenuVendor
  categories: MenuCategory[]
  onAddItemToBasketClick: (item: AddItemPayload) => void
  basketIsLoading: boolean
  closeModalWithoutLoading: boolean
  showPricesExTax: boolean
  taxAcronym: string
  loadingMore: boolean
  useVirtualisedList?: boolean
}

export const MenuItemsSection: React.FC<Props> = ({
  display,
  vendor,
  categories,
  onAddItemToBasketClick,
  basketIsLoading,
  closeModalWithoutLoading,
  showPricesExTax,
  taxAcronym,
  loadingMore,
  useVirtualisedList,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const cardSize = display === ItemView.Grid ? 6 : 12
  const numberOfCardsInColumn = !isMobile && display === ItemView.Grid ? 2 : 1

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const elements = useMemo(() => {
    if (!useVirtualisedList) return null
    return categories.flatMap((category, i) => {
      const res: {
        type: 'category' | 'item'
        height: number
        component: React.ReactElement
      }[] = [
        {
          type: 'category' as const,
          height: 40,
          component: (
            <Box key={i}>
              <CategoryTitle>{category.name}</CategoryTitle>
              <CategoryDivider />
            </Box>
          ),
        },
      ]

      for (let i = 0; i < category.items.length; i += numberOfCardsInColumn) {
        const lastItemMarginBottom = category.items.length <= i + 1 ? 32 : 0
        const itemHeight = 280
        const itemPadding = 16

        res.push({
          type: 'item' as const,
          height: itemHeight + itemPadding + lastItemMarginBottom,
          component: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${numberOfCardsInColumn}, 1fr)`,
                gap: 2,
                py: `${itemPadding}px`,
                mb: `${lastItemMarginBottom}px`,
              }}
            >
              {Array.from({ length: numberOfCardsInColumn }).map((_, j) =>
                category.items[i + j] ? (
                  <RedesignedMenuItemCardAug2024
                    key={j}
                    numberInCol={numberOfCardsInColumn}
                    taxAcronym={taxAcronym}
                    showPricesExTax={showPricesExTax}
                    item={category.items[i + j]}
                    vendor={vendor}
                    onAddItemToBasketClick={onAddItemToBasketClick}
                    basketIsLoading={basketIsLoading}
                    closeModalWithoutLoading={closeModalWithoutLoading}
                  />
                ) : null,
              )}
            </Box>
          ),
        })
      }

      return res
    })
  }, [
    useVirtualisedList,
    categories,
    numberOfCardsInColumn,
    taxAcronym,
    showPricesExTax,
    vendor,
    onAddItemToBasketClick,
    basketIsLoading,
    closeModalWithoutLoading,
  ])

  const rowHeights = useMemo(() => elements?.map(({ height }) => height), [elements])

  if (!isClient) return null

  return categories.length ? (
    <>
      {!useVirtualisedList &&
        categories.map((category, i) => (
          <CategorySection key={i} id={`scrollTo-${category.name}`}>
            <CategoryTitle>{category.name}</CategoryTitle>
            <CategoryDivider />
            <MuiGrid container spacing={1}>
              {category.items.map((item) => (
                <MuiGrid item md={cardSize} sm={cardSize} xs={12} key={`${i}-${item._id}`}>
                  <RedesignedMenuItemCardAug2024
                    numberInCol={numberOfCardsInColumn}
                    taxAcronym={taxAcronym}
                    showPricesExTax={showPricesExTax}
                    item={item}
                    vendor={vendor}
                    onAddItemToBasketClick={onAddItemToBasketClick}
                    basketIsLoading={basketIsLoading}
                    closeModalWithoutLoading={closeModalWithoutLoading}
                  />
                </MuiGrid>
              ))}
            </MuiGrid>
          </CategorySection>
        ))}

      {useVirtualisedList && elements && (
        <Box
          sx={{
            flex: 1,
            flexGrow: 1,
            minHeight: 1000,
          }}
        >
          <WindowScroller>
            {({ height, isScrolling, registerChild, scrollTop }) => (
              // @ts-expect-error - react-window types are incorrect
              <div ref={registerChild}>
                <List
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  rowCount={elements.length}
                  rowHeight={({ index }) => rowHeights?.[index] || 0}
                  width={2000}
                  rowRenderer={({ index, style }) => {
                    const element = elements[index]
                    return <div style={style}>{element.component}</div>
                  }}
                  autoWidth
                  autoContainerWidth
                  scrollTop={scrollTop}
                />
              </div>
            )}
          </WindowScroller>
        </Box>
      )}
      {loadingMore ? <LoadingIndicator size="small" /> : null}
    </>
  ) : (
    <MassiveBox>
      <Text>Oops, looks like no menu items match this filter selection.</Text>
      <Text>Remove the filter/s to view all menu items.</Text>
    </MassiveBox>
  )
}
