// EU:
export const DEFAULT_TENANT_ID = '64c934f210528b243cba6142'
// APAC:
// export const DEFAULT_TENANT_ID = '64c9351947c111d57ab98ef7'
// COCO DI MAMA:
// export const DEFAULT_TENANT_ID = '66ab5c5872a12355ff1f968a'
// MYMY CATERING:
// export const DEFAULT_TENANT_ID = '670fa10d073d99a16586e7ce'
// FEEDWELL:
// export const DEFAULT_TENANT_ID = '67289e1edd6cdd85ffe3ae7e'
// Morsy:
// export const DEFAULT_TENANT_ID = '6785553d1ac47490a46b7310'
