import { TenantFromPrebuildFragment } from '@/generated/graphql'
import useTenant from '@/hooks/useTenant'

export const getDeliveryTimeLabel = (tenant: TenantFromPrebuildFragment, deliveryTime?: string) => {
  if (!deliveryTime) return null
  const shouldShowDeliveryWindow = tenant.features?.gmHideDeliveryWindow?.visibility ?? true
  const deliveryTimeLabel = !shouldShowDeliveryWindow ? deliveryTime.split(' - ')[0] : deliveryTime

  return deliveryTimeLabel
}

export const useDeliveryTimeLabel = (deliveryTime?: string) => {
  const tenant = useTenant()
  return getDeliveryTimeLabel(tenant, deliveryTime)
}
