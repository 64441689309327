import React, { useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { useTheme } from 'styled-components'
import { match } from 'ts-pattern'

import { Text, TextExtraSmall, TextSmall } from '@caterdesk/ui--typography'
import Icon from '@caterdesk/ui--icon'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { amountToPriceString, Currency } from '@caterdesk/utils--money'
import {
  validateBasketItemsDelete,
  validateBasketItemUpdate,
} from '@caterdesk/utils--gm-validation'
import Stack from '@caterdesk/ui--stack'
import Box from '@caterdesk/ui--box'

import BasketItemCard from './basket-item-card'
import { generateBasketLink } from '@/components/page-specific/gm/helpers/basket-link'
import { routerQuerySchema } from '../states/menu-page'
import { clearAllLocalBasketIds } from '../states/local-basket'
import {
  BasketFragment,
  BasketItemFragment,
  GmMenuStandingOrderDataFragment,
  MenuItemOptionType,
  UserRole,
} from '@/generated/graphql'
import { Content, Footer } from './index.styles'
import { useAuthState } from '../states/auth'
import { BasketBanner } from './basket-banner'
import { areFieldsNotNil } from '../../utils/typing'
import {
  Button,
  Divider,
  Stack as MuiStack,
  Typography,
  Box as MuiBox,
  Drawer as MuiDrawer,
  CircularProgress,
  Chip,
  Tooltip,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useRouter } from '@/components/Link'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import useCharityMeals from '@/helpers/features/charityMeals'
import { format, setDay } from 'date-fns'
import { CalendarToday } from '@mui/icons-material'
import CustomItemCard from './custom-item-card'
import { zonedTimeToUtc } from 'date-fns-tz'
import { capitalise } from '@/helpers/strings'
import { useDeliveryTimeLabel } from '../../helpers/useDeliveryTimeLabel'

export type Props = {
  isOpen: boolean
  basket: BasketFragment
  basketIsUpdating: boolean
  standingOrderData: GmMenuStandingOrderDataFragment | null
  clearLocalBasket: () => void
  currency: Currency
  minOrderValue?: number | null
  minOrderValueExTax?: number | null
  closeBasket: () => void
  setOrderDetailsModalOpen: (open: boolean) => void
  onNonNumberedItemQuantityChange: (
    item: Pick<BasketItemFragment, 'itemReference'>,
    qty: number,
  ) => void
  onNumberedItemQuantityChange: (
    item: Pick<
      BasketItemFragment,
      'itemReference' | 'item' | 'qty' | 'acknowledgedAt' | 'note' | 'options'
    >,
  ) => void
  acknowledgeEditedItems: (items: BasketItemFragment[]) => void
  removeInvalidItemsFromBasket: (items: Pick<BasketItemFragment, 'itemReference'>[]) => void
  onDeleteClick: (basket: BasketFragment, item: Pick<BasketItemFragment, 'itemReference'>) => void
  checkoutCallback: (basketId: string) => void
  showPricesExTax: boolean
  taxAcronym: string
  isPersistentDrawer: boolean
}

export const BasketDrawer: React.FC<Props> = ({
  isOpen,
  basket,
  basketIsUpdating,
  clearLocalBasket,
  currency,
  minOrderValue,
  minOrderValueExTax,
  closeBasket,
  onNonNumberedItemQuantityChange,
  onNumberedItemQuantityChange,
  acknowledgeEditedItems,
  removeInvalidItemsFromBasket,
  onDeleteClick,
  checkoutCallback,
  showPricesExTax,
  taxAcronym,
  isPersistentDrawer,
  standingOrderData,
}) => {
  const theme = useTheme()
  const router = useRouter()
  const charityMeals = useCharityMeals()
  const authState = useAuthState()
  const [copied, setCopied] = useState(false)
  const [clearBasketModalOpen, setClearBasketModalOpen] = useState(false)
  const isMobileScreen = useMedia(mobileMediaQuery, false)
  const { items, settings, subtotalBreakdown, customItems } = basket
  const useMinOrderValueExTax = useFeatureFlag(SPLITS.MIN_ORDER_VALUE_EX_TAX)
  const deliveryTimeLabel = useDeliveryTimeLabel(settings.deliveryTimeSlot)

  const minOrderVal = useMinOrderValueExTax ? minOrderValueExTax : minOrderValue
  const minSpendRemaining = minOrderVal
    ? amountToPriceString(
        currency,
        minOrderVal -
          (useMinOrderValueExTax ? subtotalBreakdown.subtotalExTax : subtotalBreakdown.subtotal),
        true,
      )
    : 0
  const minSpendRemainingText = `You need to spend ${minSpendRemaining}${useMinOrderValueExTax ? ` ex. ${taxAcronym}` : ''} more to checkout`

  const reachedMinOrderValue = minOrderVal
    ? useMinOrderValueExTax
      ? subtotalBreakdown.subtotalExTax >= minOrderVal
      : subtotalBreakdown.subtotal >= minOrderVal
    : true

  const { basketId: sharedBasketId } = routerQuerySchema.parse(router.query)

  const preciseTaxRate = (taxRate: number) => {
    return Math.round(taxRate * 100 * 100) / 100
  }

  const copyBasketLink = async () => {
    await navigator.clipboard.writeText(generateBasketLink(basket.id))
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  useEffect(() => {
    if (isMobileScreen && isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      if (isMobileScreen) {
        document.body.style.overflow = 'unset'
      }
    }
  }, [isMobileScreen, isOpen])

  const clearCurrentBasket = () => {
    if (sharedBasketId) {
      location.search = ''
    } else {
      clearAllLocalBasketIds()
      clearLocalBasket()
    }
  }

  const authenticated = match(authState)
    .with({ type: 'authenticated' }, () => true)
    .with({ type: 'loading' }, { type: 'unauthenticated' }, () => false)
    .exhaustive()

  const isCompanyAdmin = match(authState)
    .with({ type: 'authenticated', user: { role: UserRole.Manager } }, () => true)
    .otherwise(() => false)
  const checkoutRestrictedForUser = basket.checkoutRestrictions.companyAdminOnly && !isCompanyAdmin
  const { validBasketItems, invalidBasketItems } = validateBasketItemsDelete(
    items,
    zonedTimeToUtc(settings.deliveryDate, 'Europe/London'),
  )
  const checkoutText = standingOrderData ? 'Save' : 'Checkout'
  const editedItems = validBasketItems.filter((item) => validateBasketItemUpdate(item))
  const [showInvalidAlert, setShowInvalidAlert] = useState(invalidBasketItems.length > 0)
  const [showEditedAlert, setShowEditedAlert] = useState(editedItems.length > 0)

  const checkout = () => {
    if (invalidBasketItems.length > 0) removeInvalidItemsFromBasket(invalidBasketItems)
    if (editedItems.length > 0) acknowledgeEditedItems(editedItems)
    checkoutCallback(basket.id)
  }

  const acknowledgeInvalidItems = () => {
    setShowInvalidAlert(false)
    removeInvalidItemsFromBasket(invalidBasketItems)
  }

  const acknowledgeItems = () => {
    setShowEditedAlert(false)
    acknowledgeEditedItems(editedItems)
  }

  useEffect(() => {
    setShowInvalidAlert(invalidBasketItems.length > 0)
  }, [invalidBasketItems.length])

  return (
    <>
      <Dialog open={clearBasketModalOpen} onClose={() => setClearBasketModalOpen(false)}>
        <DialogTitle>Clear your basket</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will delete the items in your basket and will start a new draft
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="warning"
            fullWidth
            onClick={() => setClearBasketModalOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="warning" fullWidth onClick={clearCurrentBasket}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
      <MuiDrawer
        variant={isPersistentDrawer ? 'persistent' : 'temporary'}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { sm: 407, xs: '100%' },
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'unset',
          },
        }}
        disableScrollLock
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
        open={isOpen}
        onClose={() => closeBasket()}
      >
        <BasketBanner
          onCloseBasket={() => closeBasket()}
          standingOrderName={standingOrderData?.name}
          basketFriendlyId={basket.friendlyId}
        />
        <MuiStack padding={1} spacing={1} direction="row" alignItems="center">
          {standingOrderData ? (
            <>
              <Typography>{capitalise(standingOrderData.recurrence)}</Typography>
              <MuiStack direction="row" spacing={0.5}>
                {standingOrderData.daysOfOccurrence?.map((weekday) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={format(setDay(new Date(), weekday), 'EEEEEE')}
                    key={weekday}
                  />
                ))}
              </MuiStack>
            </>
          ) : (
            <>
              <CalendarToday fontSize="medium" color="primary" />
              <Typography textAlign="left">
                {format(new Date(settings.deliveryDate), 'do MMM yyyy')}
                &nbsp;
              </Typography>
            </>
          )}

          <Typography>{deliveryTimeLabel}</Typography>
        </MuiStack>
        <MuiBox
          style={{
            background: theme.colors.background,
            padding: '8px 8px 16px',
            height: 'auto',
            flexGrow: 1,
          }}
        >
          <Content>
            <Stack direction="vertical" alignItems="stretch" spacing={4}>
              {showInvalidAlert && (
                <Alert severity="error" onClose={acknowledgeInvalidItems}>
                  <AlertTitle>Some menu items are no longer available</AlertTitle>
                  Please get in contact with us
                </Alert>
              )}
              {showEditedAlert && (
                <Alert severity="error" onClose={acknowledgeItems}>
                  <AlertTitle>Some items have been updated by the vendor</AlertTitle>
                  Please review before checkout
                </Alert>
              )}
              {validBasketItems.map((item) => {
                const itemHasNumberOptions = item.options
                  .filter(areFieldsNotNil(['category']))
                  .some((o) => o.category.optionType === MenuItemOptionType.Numbers)
                return (
                  <BasketItemCard
                    showPricesExTax={showPricesExTax}
                    taxAcronym={taxAcronym}
                    key={item.itemReference}
                    item={item}
                    currency={currency}
                    debounceQuantityChange={!itemHasNumberOptions}
                    onQuantityChange={(qty) => {
                      itemHasNumberOptions
                        ? onNumberedItemQuantityChange(item)
                        : onNonNumberedItemQuantityChange(item, qty)
                    }}
                    onDeleteClick={() => onDeleteClick(basket, item)}
                  />
                )
              })}
              {customItems.map((item) => (
                <CustomItemCard
                  key={item.id}
                  item={item}
                  taxAcronym={taxAcronym}
                  currency={currency}
                />
              ))}
            </Stack>
            {minOrderVal && !reachedMinOrderValue && (
              <Alert severity="warning">{minSpendRemainingText}</Alert>
            )}
            {basket.discountBreakdown.totalExTax < 0 && (
              <Stack spacing={4} justifyContent="space-between">
                <Typography color="text.secondary" variant="body2">
                  {basket.hasEnterpriseDiscount ? 'Enterprise' : 'Coupon'} discount
                </Typography>
                <MuiStack direction="row" spacing={0.5} alignItems="center">
                  {basketIsUpdating ? <CircularProgress size={13} /> : null}
                  <Typography color="text.secondary" variant="body2">
                    {amountToPriceString(currency, basket.discountBreakdown.totalExTax, true)}
                  </Typography>
                </MuiStack>
              </Stack>
            )}
            {basket.fees.map((fee) => (
              <Stack key={fee.code} spacing={4} justifyContent="space-between">
                <TextExtraSmall style={{ fontWeight: 500 }}>{fee.description}</TextExtraSmall>
                <MuiStack direction="row" spacing={0.5} alignItems="center">
                  {basketIsUpdating ? <CircularProgress size={13} /> : null}
                  <Typography color="text.secondary" variant="body2">
                    {amountToPriceString(currency, fee.totalExTax, true)}
                  </Typography>
                </MuiStack>
              </Stack>
            ))}
            <Divider />
            <MuiStack direction="column" spacing={2}>
              {basket.basketTotalBreakdown.taxRateTotals.map((rate) => (
                <MuiStack key={rate.rate} direction="row" justifyContent="space-between">
                  <Typography color="text.secondary" variant="body2">
                    {taxAcronym} @ {preciseTaxRate(rate.rate)}% item subtotal
                  </Typography>
                  <MuiStack direction="row" spacing={0.5} alignItems="center">
                    {basketIsUpdating ? <CircularProgress size={13} /> : null}
                    <Typography color="text.secondary" variant="body2">
                      {amountToPriceString(currency, rate.totalExTax, true)}
                    </Typography>
                  </MuiStack>
                </MuiStack>
              ))}
              <MuiStack direction="row" justifyContent="space-between">
                <Typography color="text.secondary" variant="body2">
                  Total {taxAcronym}
                </Typography>
                <MuiStack direction="row" spacing={0.5} alignItems="center">
                  {basketIsUpdating ? <CircularProgress size={13} /> : null}
                  <Typography color="text.secondary" variant="body2">
                    {amountToPriceString(currency, basket.basketTotalBreakdown.taxValue, true)}
                  </Typography>
                </MuiStack>
              </MuiStack>
            </MuiStack>
          </Content>
        </MuiBox>

        <Footer backgroundColor="canvas" padding={{ y: 8 }}>
          <Stack direction="vertical" divider spacing={8} alignItems="stretch">
            <Box padding={{ x: 16 }}>
              <Stack justifyContent="space-between">
                <Stack spacing={4}>
                  <Text style={{ fontWeight: 500, color: theme.colors.text_heading }}>
                    Total{!standingOrderData ? ' to pay' : ''}
                  </Text>
                  <TextExtraSmall>
                    ({authenticated ? `inc. ${taxAcronym}` : `inc. ${taxAcronym} exc. Product Fee`})
                  </TextExtraSmall>
                </Stack>
                <MuiStack direction="row" spacing={0.5} alignItems="center">
                  {basketIsUpdating ? <CircularProgress size={13} /> : null}
                  <Text style={{ fontWeight: 500, color: theme.colors.text_heading }}>
                    {amountToPriceString(currency, basket.basketTotalBreakdown.totalIncTax, true)}
                  </Text>
                </MuiStack>
              </Stack>
            </Box>
            {charityMeals.enabled && (
              <Box padding={{ x: 16 }}>
                <Stack spacing={4}>
                  <MuiStack direction="row" spacing={1} alignItems="center">
                    <Icon icon="heart" size={14} fill={theme.colors.special} />
                    <TextSmall style={{ color: theme.colors.special }}>
                      You’re feeding {settings.headCount}, we’ll feed {settings.headCount} too
                    </TextSmall>
                    <Tooltip
                      title="For every meal you order with Feedr, we donate a nutritious meal to a school child in rural India, in partnership with the Akshaya Patra Foundation."
                      placement="left"
                    >
                      <MuiBox
                        sx={{
                          pointerEvents: 'all',
                        }}
                      >
                        <Icon icon="info" size={14} fill={theme.colors.special} />
                      </MuiBox>
                    </Tooltip>
                  </MuiStack>
                </Stack>
              </Box>
            )}
            <Box padding={{ x: 16 }}>
              <Stack spacing={8} direction="vertical" alignItems="stretch">
                <div>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={
                      checkoutRestrictedForUser ||
                      basketIsUpdating ||
                      (standingOrderData && !basket.editSessionId) ||
                      (!basket.settings.feedrAdminApproved && !reachedMinOrderValue)
                    }
                    loading={basketIsUpdating}
                    onClick={checkout}
                  >
                    {checkoutRestrictedForUser
                      ? 'Contact your company admin to checkout'
                      : checkoutText}
                  </LoadingButton>
                </div>

                {!standingOrderData ? (
                  <Button fullWidth variant="outlined" size="large" onClick={copyBasketLink}>
                    {copied ? 'Copied!' : 'Copy basket link'}
                  </Button>
                ) : null}
              </Stack>
            </Box>
          </Stack>
        </Footer>
      </MuiDrawer>
    </>
  )
}
