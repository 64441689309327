import { Stack, InputAdornment } from '@mui/material'
import { useDebounce } from 'use-debounce'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'

type Props = {
  onItemNameChange: (itemName: string) => void
  itemNameSearchValue?: string
}

const MenuItemSearch: React.FC<Props> = ({ onItemNameChange, itemNameSearchValue }) => {
  const [input, setInput] = useState(itemNameSearchValue || '')
  const [debouncedInput] = useDebounce(input, 500)

  useEffect(() => {
    if (debouncedInput !== input) return
    onItemNameChange(debouncedInput)
  }, [debouncedInput])

  useEffect(() => {
    setInput(itemNameSearchValue || '')
  }, [itemNameSearchValue])

  return (
    <Stack spacing={1} direction="column">
      <TextField
        autoComplete="off"
        id="menu-item-search"
        label="Search for items"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setInput(e.target.value)}
        size="small"
        type="search"
        value={input}
      />
    </Stack>
  )
}

export default MenuItemSearch
