import React from 'react'
import { useMedia } from 'react-use'
import { match } from 'ts-pattern'
import { sortBy } from 'lodash'
import { TextExtraSmall, Title } from '@caterdesk/ui--typography'
import Stack from '@caterdesk/ui--stack'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { GmItemPackaging, MenuItemOptionType } from '@/generated/graphql'
import { useTheme } from 'styled-components'
import { TickOptions } from './tick-options'
import { NumberOptions } from './number-options'
import { SectionTitle } from './index.styles'
import OptionGuidanceText from './option-guidance-text'
import { SelectedOptionItem } from './menu-item-modal'
import { MenuItemOption, MenuOptionItem } from '../../domain/menu-item-option'
import { MenuItem } from '../../domain/menu-item'
import { MenuVendor } from '../../domain/menu-vendor'
import { useFeatureFlag, SPLITS } from '@/components/page-specific/gm/helpers/useFeatureFlag'
import { Box, Button, Chip, Stack as MuiStack, TextField, Typography } from '@mui/material'
import StyledChip from './styled-chip'
import { GridViewRounded, Person, SquareRounded } from '@mui/icons-material'
import { getTenantFromWindow } from '@/helpers/tenants'
import { useRouter } from '@/components/Link'
import DietaryListMui from '@/components/DietaryListMui'
import { useAuthState } from '../states/auth'

type Props = {
  vendor: MenuVendor
  item: MenuItem
  quantity: number
  selectedOptions: Map<string, Map<string, SelectedOptionItem>>
  selectOptionItem: (option: MenuItemOption, item: MenuOptionItem, quantity?: number) => void
  onAllergyButtonClick: () => void
  note: string
  noteIsEditable: boolean
  setNote: (note: string) => void
  taxAcronym: string
  showPricesExTax: boolean
}

const HotOrColdTag: React.FC<{ isHot: boolean }> = ({ isHot }) =>
  match(isHot)
    .with(true, () => (
      <Chip
        size="small"
        label="HOT"
        sx={{
          backgroundColor: '#F4E9EC',
          color: '#781C38',
        }}
      />
    ))
    .with(false, () => (
      <Chip
        size="small"
        label="COLD"
        sx={{
          backgroundColor: '#EBF7FB',
          color: '#3AADD8',
        }}
      />
    ))
    .exhaustive()

const PackagingTag: React.FC<{ packaging: GmItemPackaging | null }> = ({ packaging }) =>
  match(packaging)
    .with(GmItemPackaging.Individual, () => (
      <StyledChip icon={<SquareRounded />} label="INDIVIDUAL BOXES" />
    ))
    .with(GmItemPackaging.Sharing, () => <StyledChip icon={<GridViewRounded />} label="SHARING" />)
    .with(GmItemPackaging.Both, GmItemPackaging.Unknown, null, () => <></>)
    .exhaustive()

export const OverviewTab: React.FC<Props> = ({
  vendor,
  item,
  quantity,
  selectedOptions,
  selectOptionItem,
  onAllergyButtonClick,
  note,
  noteIsEditable,
  setNote,
  taxAcronym,
  showPricesExTax,
}) => {
  const theme = useTheme()
  const router = useRouter()
  const isMobileScreen = useMedia(mobileMediaQuery, false)
  const orderedOptions = sortBy(item.options, (options) => options?.position)
  const tenant = getTenantFromWindow()
  const country = router.query.lang || ''
  const auth = useAuthState()

  const showItemNotes = !useFeatureFlag(SPLITS.REMOVE_ITEM_NOTES, {
    country: country.toString().split('-')[1].toUpperCase(),
    tenant: tenant?.id || '',
    service: 'GM',
    accountId: auth.type === 'authenticated' ? (auth.user.account?.id ?? '') : '',
  })

  const handleNoteChange = (note: string) => {
    setNote(note)
  }
  let servesAmount = `${item.servings}`
  if (item.isPricePerHead) {
    servesAmount = `${item.minQty}`
    if (item.minQty > 1) servesAmount += '+'
  }

  return (
    <>
      <Box px={3} py={1}>
        {isMobileScreen && (
          <Title size="m" style={{ fontWeight: 600 }}>
            {item.name}
          </Title>
        )}
        <Typography variant="body1" fontWeight="400" margin="0 0 0.75em" whiteSpace="pre-wrap">
          {item.description}
        </Typography>
        <Button size="small" onClick={onAllergyButtonClick}>
          Have an allergy?
        </Button>
      </Box>
      <Box
        sx={{
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: 'divider',
        }}
      >
        <Stack divider>
          {item.dietaries.length > 0 && (
            <Box p={2}>
              <DietaryListMui allergens={[]} dietaries={item.dietaries} />
            </Box>
          )}
          <Box p={2}>
            <DietaryListMui
              isComboItem={Boolean(item.isCombo)}
              allergens={item.allergens}
              dietaries={[]}
              subAllergens={item.subAllergens}
            />
          </Box>
        </Stack>
      </Box>
      <Box
        px={1}
        sx={{
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: 'divider',
        }}
      >
        <Stack divider>
          {typeof item.isHot === 'boolean' && (
            <Box p={1}>
              <HotOrColdTag isHot={item.isHot} />
            </Box>
          )}
          <Box p={1}>
            <Stack spacing={8}>
              <StyledChip label={`SERVES ${servesAmount}`} icon={<Person />} />
              <PackagingTag packaging={item.packaging} />
            </Stack>
          </Box>
        </Stack>
      </Box>
      {orderedOptions.map(
        (option) =>
          option?.name && (
            <Box key={option._id}>
              <Box
                py={2}
                px={3}
                bgcolor="rgb(250, 250, 250)"
                sx={{
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: 'divider',
                }}
              >
                <Stack justifyContent="space-between">
                  <SectionTitle color={theme.colors.text_heading}>{option.name}</SectionTitle>
                  <OptionGuidanceText option={option} quantity={quantity} />
                </Stack>
                {showPricesExTax ? (
                  <MuiStack direction="row-reverse">
                    <Typography color="text.secondary" variant="caption">
                      (prices ex {taxAcronym})
                    </Typography>
                  </MuiStack>
                ) : null}
              </Box>
              {match(option.optionType)
                .with(MenuItemOptionType.Ticks, () => (
                  <TickOptions
                    showPricesExTax={showPricesExTax}
                    option={option}
                    currency={vendor.currency}
                    selectedOptionItems={selectedOptions.get(option._id)}
                    selectOptionItem={selectOptionItem}
                  />
                ))
                .with(MenuItemOptionType.Numbers, () => (
                  <NumberOptions
                    showPricesExTax={showPricesExTax}
                    option={option}
                    currency={vendor.currency}
                    quantity={quantity}
                    selectedOptionItems={selectedOptions.get(option._id)}
                    selectOptionItem={selectOptionItem}
                  />
                ))
                .otherwise(() => null)}
            </Box>
          ),
      )}
      {showItemNotes && (
        <Box py={2} px={3}>
          <TextField
            label="Add notes"
            multiline
            minRows={2}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!noteIsEditable}
            value={note}
            onChange={(e) => handleNoteChange(e.target.value)}
          />
        </Box>
      )}
      {item.minQty > 1 && (
        <Box bgcolor="rgb(250, 250, 250)" py={1}>
          <TextExtraSmall style={{ color: theme.colors.primary_main }}>
            You must order at least {item.minQty} of this item
          </TextExtraSmall>
        </Box>
      )}
    </>
  )
}
