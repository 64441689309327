import {
  BasketFragment,
  BasketItemFragment,
  useRemoveItemFromBasketMutation,
} from '@/generated/graphql'

export type RemoveItemFromBasket = (
  basket: BasketFragment,
  item: Pick<BasketItemFragment, 'itemReference'>,
) => void

export const useRemoveItemMutation = (
  setDeliveryFee?: number,
  isStandingOrderTemplate?: boolean,
  editSessionId?: string | null,
) => {
  const [run, result] = useRemoveItemFromBasketMutation()

  if (!setDeliveryFee && setDeliveryFee !== 0) throw new Error('No delivery fee set')

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const removeItemFromBasket: RemoveItemFromBasket = (basket, item) => {
    const optimisticItems = basket.items.filter((i) => i.itemReference !== item.itemReference)

    void run({
      variables: {
        basketId: basket.id,
        editSessionId: editSessionId ?? null,
        isStandingOrderTemplate: Boolean(isStandingOrderTemplate),
        itemReference: item.itemReference,
      },
      optimisticResponse: {
        basket: {
          ...basket,
          items: optimisticItems,
        },
      },
    })
  }

  return {
    run: removeItemFromBasket,
    loading: result.loading,
    error,
  }
}
