import React, { useState } from 'react'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { Tab, Tabs } from '@mui/material'

type Props = {
  categories: MenuFilterCategory[]
  selectedCategory: SelectedMenuFilterCategory
}

const CategoryTabs: React.FC<Props> = ({ categories, selectedCategory }) => {
  const [categoryTab, setCategoryTab] = useState<string | null>(selectedCategory.name)

  const handleCategoryTabChange = (_event: React.SyntheticEvent, newCategoryName: string) => {
    setCategoryTab(newCategoryName)
    const categoryHeader = document.getElementById(`scrollTo-${newCategoryName}`)

    if (categoryHeader) {
      const offset = 160
      const categoryHeaderPosition = categoryHeader.getBoundingClientRect().top + window.scrollY
      const offsetPosition = categoryHeaderPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Tabs
      value={categoryTab}
      onChange={handleCategoryTabChange}
      variant="scrollable"
      scrollButtons="auto"
      textColor="primary"
      allowScrollButtonsMobile
      sx={{ marginTop: 2, marginBottom: 2 }}
      aria-label="Category Tabs"
    >
      {categories
        .filter((category) => !category.name.includes('dishes'))
        .map((category) => (
          <Tab
            key={category.id}
            label={category.name}
            value={category.name}
            sx={{ color: 'primary.main' }}
            aria-label={`Select ${category.name}`}
          />
        ))}
    </Tabs>
  )
}

export default CategoryTabs
